import TestModal from "./Component/testModal.vue";
import UploadModal from "./Component/UploadModal.vue";
import TagsModal from "./Component/TagsModal.vue";
import CreateTemplateModal from "../Common/CreateTemplateModal/index.vue";
import GalleryModal from "./Component/GalleryModal.vue";
import { EmailEditor } from "vue-email-editor";
import ApiClient from "@/services/ApiClient";
import S3Service from "@/services/S3Service";

export default {
  name: "ListEmailTemplates",
  components: {
    EmailEditor,
    CreateTemplateModal,
    GalleryModal,
    TagsModal,
    TestModal,
    UploadModal,
  },
  data() {
    return {
      audiencesList: [],
      choosenAudience: null,
      confirmationFastJourney: false,
      openTestModal: false,
      openUploadModal: false,
      galleryDialog: false,
      showGallery: false,
      isLoaded: false,
      uploadingTemplate: false,
      showModal: false,
      loadingTemplate: false,
      showInformations: true,
      typeTemplate: "",
      stepperValue: 1,
      steppers: [
        { id: 1, label: "Conexão", icon: "$check_rounded" },
        { id: 2, label: "Mapeamento de Dados", icon: "$check_rounded" },
        { id: 3, label: "Informações auxiliares", icon: "$check_rounded" },
        { id: 4, label: "Atualização", icon: "$check_rounded" },
      ],

      step: 1,
      items: [],
      mapping: [
        "ID Único",
        "Nome do usuário (opcional)",
        "Telefone",
        "ID SDK",
        "E-mail",
      ],
      mapping2: ["ID da compra", "Valor da compra", "Data da compra"],
      mapping3: ["Valor", "Produto"],
      text: "center",
      zStyle: {
        padding: "1.5rem .5rem",
        display: "flex",
        flexDirection: "column",
      },
      http: new ApiClient(),
      S3Service: new S3Service(),
      choosenConnection: null,
      templateToSave: {
        message: "",
        templateType: "email",
        templateName: "",
        isPublic: false,
        emailSubject: null,
        senderName: null,
        emailSender: null,
      },
      // vue-email-editor
      minHeight: "100px",
      dbConfigList: [],
      locale: "en",
      projectId: 232593, // replace with your project id
      tools: {
        image: {
          properties: {
            src: {
              value: {
                url: "",
              },
            },
          },
        },
      },
      emailRules: [
        (v) => !!v || "E-mail requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
      ],
      sendEmailRules: [
        (v) => !!v || "E-mail requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
        (v) =>
          this.checkEmailDomain(v) ||
          `Domínio para envio deve ser ${this.emailDomain}`,
      ],
      cardAudienceStyle: {
        background: "#FFFFFF",
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        padding: "1rem",
        flex: "1 1 200px",
        cursor: "pointer",
      },
      submitingFile: false,
      cardAudienceStyle2: {
        display: "flex",
        // alignItems: "flex-start",
        gap: "1rem",
        padding: "1rem 0",
        flexWrap: "wrap",
        color: "#333333",
      },
      tags: null,
      openUtmModal: null,
      utmNames: [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_id",
        "utm_term",
        "utm_content",
      ],
      openTagsModal: false,
      canShow: false,
      options: {},
      showTagsModal: false,
      imagesUpload: [],
      emailDomain: "",
      repoImages: [],
      defaultEmailSender: null,
      defaultValues: {},
      defaultFormats: {},
      openVariablesModal: false,
      variables: [],
      emailDomains: [],
      databaseFields: [],
      sendConfigurationDialog: false,
      firstData: null,
      templateZipFile: null,
      imagesForNewTemplate: [],
      dateOptions: [
        {
          format: "YYYY-MM-DD",
          description: "ISO 8601 date format",
          example: "2023-05-02",
        },
        {
          format: "DD/MM/YYYY",
          description: "UK/Canada date format",
          example: "02/05/2023",
        },
        {
          format: "MM/DD/YYYY",
          description: "USA date format",
          example: "05/02/2023",
        },
        {
          format: "DD/MM/YY",
          description: "Short USA date format",
          example: "05/02/23",
        },
        {
          format: "Do MMMM YYYY",
          description: "Long format",
          example: "2nd May 2023",
        },
      ],
      dateTimeOptions: [
        {
          format: "YYYY-MM-DD",
          description: "ISO 8601 date format",
          example: "2023-05-02",
        },
        {
          format: "DD/MM/YYYY",
          description: "UK/Canada date format",
          example: "02/05/2023",
        },
        {
          format: "MM/DD/YYYY",
          description: "USA date format",
          example: "05/02/2023",
        },
        {
          format: "DD/MM/YY",
          description: "Short USA date format",
          example: "05/02/23",
        },
        {
          format: "Do MMMM YYYY",
          description: "Long format",
          example: "2nd May 2023",
        },
        {
          format: "YYYY-MM-DDTHH:mm:ssZ",
          description: "ISO 8601 format",
          example: "2023-05-02T14:30:00Z",
        },
        {
          format: "DD/MM/YYYY HH:mm:ss",
          description: "UK/Canada format",
          example: "02/05/2023 14:30:00",
        },
        {
          format: "DD/MM/YYYY HH:mm",
          description: "UK/Canada format",
          example: "02/05/2023 14:30",
        },
        {
          format: "MM/DD/YYYY HH:mm:ss",
          description: "USA format",
          example: "05/02/2023 14:30:00",
        },
        {
          format: "MM/DD/YYYY HH:mm",
          description: "USA format",
          example: "05/02/2023 14:30",
        },
        {
          format: "YYYY-MM-DD HH:mm:ss",
          description: "Database format",
          example: "2023-05-02 14:30:00",
        },
        {
          format: "YYYY-MM-DD HH:mm:ss.SSS",
          description: "UK/Canada format",
          example: "2023-03-08 00:00:00.000000",
        },
      ],
      appearance: {
        theme: "dark",
        panels: {
          tools: {
            dock: "right",
          },
        },
      },
    };
  },
  computed: {
    isEditing() {
      return this.$route.query.id != null;
    },
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.showInformations = false;
    }
  },
  watch: {
    isLoaded(nv) {
      if (nv && this.$route.params.shouldRenderPredata) {
        this.openUploadModal = true;
      }
    },
    openUploadModal(nv) {
      if (!nv) this.imagesForNewTemplate = [];
    },
  },
  async created() {
    await this.getPrimaryDataConfigList();
    await this.loadRepoImages();

    if (this.$route.query.id) {
      await this.http
        .get(`templatesConfiguration/findOne/${this.$route.query.id}`)
        .then(async (resp) => {
          // PARA CORRETA EDIÇÃO, ACREDITO QUE PRECISAREMOS VERIFICAR SE ELE ESTÁ VINDO DO .html ou .deging, PARA MONTAR ELE DA FORMA ADEQUADA
          this.templateToSave = resp;

          const response = await this.http.get("messageCredentials");
          if (!this.templateToSave.emailSender) {
            this.templateToSave.emailSender = response.emailSender;

            this.templateToSave.senderName = response.senderName;
            this.templateToSave.replyTo = response.replyTo;
          }

          console.log(response.emailDomain);
          if (response.emailDomain) {
            this.emailDomains = response.emailDomain.split(",");
          } else {
            this.emailDomains = ["@" + response.emailSender.split("@")[1]];
          }

          this.templateToSave.emailDomain =
            "@" + this.templateToSave.emailSender.split("@")[1];

          console.log(response);
          if (!this.templateToSave.utm_source)
            this.templateToSave.utm_source = response.utm_source;
          if (!this.templateToSave.utm_medium)
            this.templateToSave.utm_medium = response.utm_medium;
          if (!this.templateToSave.utm_campaign)
            this.templateToSave.utm_campaign = response.utm_campaign;
          if (!this.templateToSave.utm_id)
            this.templateToSave.utm_id = response.utm_id;
          if (!this.templateToSave.utm_term)
            this.templateToSave.utm_term = response.utm_term;
          if (!this.templateToSave.utm_content)
            this.templateToSave.utm_content = response.utm_content;

          this.templateToSave.emailSender =
            this.templateToSave.emailSender.split("@")[0];

          this.canShow = true;
          this.showInformations = true;
          if (this.templateToSave.databaseConfigId) {
            this.choosenConnection = this.dbConfigList.find(
              (x) => x.id == this.templateToSave.databaseConfigId
            );

            await this.chooseCollection();
            await this.getAudiences();
          }

          if (resp.defaultValues)
            this.defaultValues = JSON.parse(resp.defaultValues);

          if (resp.defaultFormats)
            this.defaultFormats = JSON.parse(resp.defaultFormats);
        })
        .catch((err) => {
          throw err;
        });
    } else {
      const response = await this.http.get("messageCredentials");
      this.templateToSave.emailSender = response.emailSender.split("@")[0];
      this.templateToSave.senderName = response.senderName;

      console.log(response.emailDomain);
      if (response.emailDomain) {
        this.emailDomains = response.emailDomain.split(",");
      } else {
        this.emailDomains = ["@" + response.emailSender.split("@")[1]];
      }

      this.templateToSave.emailDomain = this.emailDomains[0];

      this.templateToSave.replyTo = response.replyTo;
      this.templateToSave.allowOptout = true;

      this.templateToSave.utm_source = response.utm_source;
      this.templateToSave.utm_medium = response.utm_medium;
      this.templateToSave.utm_campaign = response.utm_campaign;
      this.templateToSave.utm_id = response.utm_id;
      this.templateToSave.utm_term = response.utm_term;
      this.templateToSave.utm_content = response.utm_content;

      this.canShow = true;
    }

    // if(this.$route.params.shouldRenderPredata) {
    //   // await this.editorLoaded()

    // }
  },
  methods: {
    async getAudiences() {
      try {
        const res = await this.http.get(
          `audience/list?databaseConfigId=${this.templateToSave.databaseConfigId}`
        );
        this.audiencesList = res;
      } catch (error) {
        console.error(error);
      }
    },
    createSimpleJourney() {
      if (this.$refs.confirmFastForm.validate()) {
        let obj = {};

        obj.template = { ...this.templateToSave };
        obj.audience = { ...this.choosenAudience };

        this.$store.dispatch("app/CREATE_BASIC_JOURNEY", obj);

        this.$router.push("/journey");
      }
    },
    checkEmailDomain(v) {
      return v.split("@")[1] === this.emailDomain;
    },
    closeGallery() {
      this.galleryDialog = false;
    },
    copyToClipBoard(text) {
      navigator.clipboard.writeText("");
      navigator.clipboard
        .writeText(text)
        .then(() => {})
        .catch(() => {});
    },
    toggleGallery() {
      this.showGallery = !this.showGallery;
    },
    async openFilePicker() {
      this.$refs.file.click();
    },
    async openImagePicker() {
      this.$refs.imageFile.click();
    },
    async openImagePickerForTemplate() {
      this.$refs.imageFileForTemplate.click();
    },
    async openHTMLPickerForTemplate() {
      this.$refs.HTMLPickerForTemplate.click();
    },
    async loadRepoImages() {
      // this.repoImages = [
      //   "/images/mock/mock-1.jpg",
      //   "/images/mock/mock-2.jpg",
      //   "/images/mock/mock-3.jpg",
      //   "/images/mock/mock-4.jpg",
      //   "/images/mock/mock-5.jpg",
      // ];

      const $context = this;
      this.repoImages = await $context.http.get(
        "s3/getTemplatesImagesByCompanyId"
      );

      const temp = [];

      for (let index = 0; index < this.repoImages.length; index += 3) {
        let arrTemp = [];
        arrTemp.push(
          "https://data-to-act-front.s3.amazonaws.com/" + this.repoImages[index]
        );
        if (index + 1 < this.repoImages.length)
          arrTemp.push(
            "https://data-to-act-front.s3.amazonaws.com/" +
              this.repoImages[index + 1]
          );
        if (index + 2 < this.repoImages.length)
          arrTemp.push(
            "https://data-to-act-front.s3.amazonaws.com/" +
              this.repoImages[index + 2]
          );

        temp.push(arrTemp);
      }

      this.repoImages = temp;
      // console.log(this.repoImages)
    },
    async uploadImage(event) {
      const $context = this;

      const arr = Array.from(event.target.files);
      arr.forEach((file) => {
        var reader = new FileReader();
        reader.onload = async () => {
          await $context.http
            .post("s3/addTemplateImages", {
              base64: reader.result,
              filename: file.name,
            })
            .then(() => {
              $context.loadRepoImages();
            });
        };
        reader.readAsDataURL(file);
      });
    },
    async uploadHtmlForTemplate(event) {
      const $context = this;
      $context.submitingFile = true;
      const file = event.target.files[0];

      const reader = new FileReader();

      reader.onload = async () => {
        let html = reader.result;

        $context.imagesForNewTemplate.forEach((image) => {
          let imgSplit = image.split("/")[2];
          html = html.replaceAll(
            `src="images/${imgSplit}`,
            `src="https://data-to-act-front.s3.amazonaws.com/${image}`
          );
        });

        if (
          $context.imagesForNewTemplate &&
          $context.imagesForNewTemplate.length
        ) {
          this.openUploadModal = false;
          $context.imagesForNewTemplate = [];
        }

        $context.$refs.emailEditor.editor.loadDesign({ html, classic: true });
        $context.submitingFile = false;
      };

      reader.readAsText(file);
    },
    async uploadImagesAndReturn(event) {
      const $context = this;
      $context.imagesForNewTemplate = [];
      const arr = Array.from(event.target.files);
      $context.imagesForNewTemplate = await $context.readFiles(arr);

      $context.$toast.success("Imagens salvas com sucesso!");
      console.log($context.imagesForNewTemplate);
    },
    async readFiles(files) {
      const $context = this;

      $context.submitingFile = true;
      const filePromises = files.map((file) => {
        // Return a promise per file
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = async () => {
            try {
              const response = await $context.addTemplateImages(
                reader.result,
                file.name
              );
              // Resolve the promise with the response value
              resolve(response);
            } catch (err) {
              reject(err);
            }
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(file);
        });
      });

      // Wait for all promises to be resolved
      const fileInfos = await Promise.all(filePromises);

      console.log("COMPLETED");

      // Profit

      $context.submitingFile = false;
      return fileInfos;
    },
    async addTemplateImages(base64, filename) {
      console.log(`ENTROU: ${filename}`);
      return await this.http.post("s3/addTemplateImages", {
        base64: base64,
        filename: filename,
      });
    },
    previewFiles(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = async () => {
        let html = reader.result;
        html = html.replaceAll(
          'src="images/',
          'src="https://data-to-act-front.s3.amazonaws.com/templates/6e01104c-9957-4c20-9aeb-8edc4b2ddf9a/'
        );
        this.$refs.emailEditor.editor.loadDesign({ html, classic: true });
      };

      reader.readAsText(file);
    },

    async previewFilesV2(event) {
      this.templateZipFile = event.target.files[0];
      this.uploadingTemplate = true;
      const reader = new FileReader();

      reader.onload = async () => {
        try {
          const templateWithImages = await this.http.post(
            "templatesConfiguration/createTemplateFromZipFile",
            {
              file: reader.result,
              name: this.templateZipFile.name,
            }
          );
          this.$refs.emailEditor.editor.loadDesign({
            html: templateWithImages,
            classic: true,
          });
          this.uploadingTemplate = false;
        } catch (error) {
          this.uploadingTemplate = false;
          this.$toast.error("Arquivo .zip está fora do padrão.");
          console.error(error);
        }
      };

      reader.readAsDataURL(this.templateZipFile);
    },

    convertHTMLToJSON2(htmlContent) {
      const parser = new DOMParser();
      const dom = parser.parseFromString(htmlContent, "text/html");
      const body = dom.body;

      function convertElementToJSON(element) {
        let json = {
          tagName: element.tagName,
          values: {},
          children: [],
        };

        for (let i = 0; i < element.attributes.length; i++) {
          let attribute = element.attributes[i];
          json.values[attribute.name] = attribute.value;
        }

        for (let i = 0; i < element.children.length; i++) {
          let childElement = element.children[i];
          let childJSON = convertElementToJSON(childElement);
          json.children.push(childJSON);
        }

        return json;
      }

      const json = convertElementToJSON(body);
      return json;
    },
    convertHTMLToJSON(htmlContent) {
      const parser = new DOMParser();
      const dom = parser.parseFromString(htmlContent, "text/html");
      const body = dom.body;

      function convertElementToJSON(element) {
        let json = {
          tagName: element.tagName,
          attributes: {},
          children: [],
        };

        for (let i = 0; i < element.attributes.length; i++) {
          let attribute = element.attributes[i];
          json.attributes[attribute.name] = attribute.value;
        }

        for (let i = 0; i < element.children.length; i++) {
          let childElement = element.children[i];
          let childJSON = convertElementToJSON(childElement);
          json.children.push(childJSON);
        }

        return json;
      }

      const json = convertElementToJSON(body);
      return json;
    },

    async chooseCollection() {
      const keys = Object.keys(JSON.parse(this.choosenConnection.fields));
      this.tags = keys;

      await this.http
        .get(`dbConfiguration/getFirstData/${this.choosenConnection.id}`)
        .then((resp) => {
          this.firstData = resp;
        })
        .catch((err) => {
          throw err;
        });

      await this.http
        .get(
          `templatesConfiguration/getVariableFields/${this.choosenConnection.id}`
        )
        .then((resp) => {
          this.databaseFields = resp;
          let keys = Object.keys(resp);
          this.variables = keys.map((key) => {
            return { value: key, type: this.databaseFields[key].type };
          });
        })
        .catch((err) => {
          throw err;
        });
    },
    showModalTemplate(typeTemplate) {
      this.showModal = true;
      this.typeTemplate = typeTemplate;
    },
    async getPrimaryDataConfigList() {
      try {
        const req = await this.http.get(
          "dbConfiguration/list?status=updated&sort=created&order=DESC"
        );
        this.dbConfigList = req;
      } catch (error) {
        this.$toast.error("Erro ao buscar lista de configurações");
      }
    },
    editorLoaded() {
      // console.log("Editor carregado");
      // if (!this.$route.query.id)
      //   this.$refs.emailEditor.editor.loadDesign({});
      // else {
      try {
        const IS_JSON = !!JSON.parse(this.templateToSave.message).design;

        if (IS_JSON)
          this.$refs.emailEditor.editor.loadDesign(
            JSON.parse(this.templateToSave.message).design
          );
        else {
          let html = JSON.parse(this.templateToSave.message).html;
          this.$refs.emailEditor.editor.loadDesign({ html, classic: true });
        }
        const iframe = document.getElementsByTagName("iframe")[0];
        iframe.classList.add("unlayer_frame");
        iframe.style.minWidth = "5px !important";
      } catch (ex) {
        const iframe = document.getElementsByTagName("iframe")[0];
        iframe.classList.add("unlayer_frame");
        iframe.style.minWidth = "5px !important";
      }
      // }

      this.isLoaded = true;
    },
    openModalTags() {
      this.showTagsModal = true;
    },
    // called when the editor has finished loading
    editorReady() {
      console.log("editorReady");
    },
    saveDesign() {
      this.$refs.emailEditor.editor.saveDesign((design) => {
        console.log("saveDesign", design);
      });
    },
    confirmRemetenteDataModal() {
      if (this.$refs.formremetente.validate()) {
        this.sendConfigurationDialog = false;
      }
    },
    async exportHtml() {
      if (
        this.$refs.form.validate() &&
        this.templateToSave.emailSubject &&
        this.templateToSave.senderName &&
        this.templateToSave.emailSender
      ) {
        const $context = this;
        $context.$refs.emailEditor.editor.exportHtml(async (data) => {
          // console.log(data.html,  'DATA-HTML')
          // console.log(data.design, 'DATA-JSON')
          $context.templateToSave.message = JSON.stringify(data);
          $context.templateToSave.description = "";

          var re = new RegExp("^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+[.][A-Za-z]+$");
          if (
            !re.test(
              $context.templateToSave.emailSender +
                $context.templateToSave.emailDomain
            )
          ) {
            this.$toast.warning("Email de envio invalido!");
            return;
          }

          this.templateToSave.emailSender =
            this.templateToSave.emailSender + this.templateToSave.emailDomain;
          this.templateToSave.databaseConfigId = this.choosenConnection?.id;

          this.templateToSave.defaultValues = JSON.stringify(
            this.defaultValues
          );

          if (!this.$route.query.id) {
            try {
              this.loadingTemplate = true;
              const req = await $context.http.post(
                "templatesConfiguration/create",
                $context.templateToSave
              );
              if (req) {
                this.$toast.success("Template salvo com sucesso!");
                this.$router.push("/templates");
              }
              this.loadingTemplate = false;
            } catch (error) {
              this.$toast.error("Erro ao salvar template!");
              this.loadingTemplate = false;
            }
          } else {
            $context.templateToSave.newEmailTemplate = true;

            $context.templateToSave.defaultValues = JSON.stringify(
              $context.defaultValues
            );

            $context.templateToSave.defaultFormats = JSON.stringify(
              $context.defaultFormats
            );

            try {
              const req = await $context.http.put(
                `templatesConfiguration/${this.$route.query.id}`,
                $context.templateToSave
              );

              if (req) {
                this.$toast.success("Template atualizado com sucesso!");
                this.$router.push("/templates");
              }
              this.loadingTemplate = false;
            } catch (error) {
              this.$toast.error("Erro ao atualizar template!");
              this.loadingTemplate = false;
            }
          }
        });
      } else {
        if (!this.$refs.form.validate())
          this.$toast.error(
            "Preencha o nome do template e selecione a conexão"
          );
        else if (
          !this.templateToSave.emailSubject ||
          !this.templateToSave.senderName ||
          !this.templateToSave.emailSender
        ) {
          this.$toast.error("Preencha todas as informações do remetente");
        }
      }
    },
  },
};
